import { React } from 'react';

import MainRouter from './routes';
function App() {
  return (
    <>
      <MainRouter />
    </>
  );
}

export default App;
